import React, { useContext, useEffect } from "react";
import styles from '../cookies/CookiesInternal.module.css';
import FooterExternal from "../../footer/FooterExternal";
import PrivacyPolicyContent from "../../privacyPolicy/PrivacyPolicyContent";
import { useNavigate } from "react-router-dom";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";

export default function PrivacyPolicyInternal(){

    const {brand,handlePageChange} = useContext(BrandCountryContext);

    useEffect(() => {
        handlePageChange(window.location.pathname);
    },[]);

    return (
        <>
            <div className={styles.background} style={{backgroundImage: brand === 'Premio'? 'url(./imgs/home/Box__Premio_Logos.jpg)' : 'url(./imgs/home/Box__Vulco_Logos.jpg)'}}>
                <div className={styles.container}>
                    <PrivacyPolicyContent/>
                </div>
            </div>
        </>
    )
}