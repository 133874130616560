import React, { useContext , useEffect } from "react";
import styles from '../generalAssets/GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";

export default function Guidelines(){
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const linkObject = {
        'Premio': "https://app.box.com/embed/s/mt3gv8hfgay5xsmqbxsscpf80hl8k48o?sortColumn=date",
        'Vulco': "https://app.box.com/embed/s/rg3o6nw0pezsf4q3xpyccbvq6u6kl8cf?sortColumn=date",
        'Spain': "https://app.box.com/embed/s/3cv6wpwvxpsdyz1aw48rlmene8yu0erj?sortColumn=date"
    }
    const handleReturnClick = () => {
        navigate('/home');
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    return(
        <div className={styles.container}>
            <TabNavigation />
            <iframe src={country === 'Spain'? linkObject[brand] : linkObject[brand]} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>
        </div>
    )
}