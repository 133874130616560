import React, { useContext , useEffect } from "react";
import styles from '../generalAssets/GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";

export default function MarketingAssets(){
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const linkObjectMA = {
        'Premio Standard': "https://app.box.com/embed/s/eb5q263344lo7ufyx50mklbk12iw576g?sortColumn=date",
        'Vulco Standard': "https://app.box.com/embed/s/rnxybse9llc4p4e5o9t2hheaq3uu388a?sortColumn=date",
        'Spain': "https://app.box.com/embed/s/kjnv8foiumm296nwpxbw07zg17oo0eu4?sortColumn=date",
        'Portugal': "https://app.box.com/embed/s/zhqbes9ke0zm4w2wxznv22jm3mva8ntb?sortColumn=date",
        'France': "https://app.box.com/embed/s/yudvmhgjgmcvtfjdl9ncj1l8lyfaitq2?sortColumn=date"
    }

    const handleReturnClick = () => {
        navigate('/home');
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    const getMALink = (brand,country) => {
        if (brand === 'Premio'){
            return linkObjectMA['Premio Standard'];
        } else if (brand === 'Vulco'){
            if(country === 'Spain'){
                return linkObjectMA['Spain'];
            } else if (country === 'Portugal'){
                return linkObjectMA['Portugal'];
            } else if (country === 'France'){
                return linkObjectMA['France'];
            } else {
                return linkObjectMA['Vulco Standard'];
            }
        }
    }

    return(
        <div className={styles.container}>
            {/* <div className={styles.navPageHeader}>
                <button onClick={handleReturnClick}>Return</button>
                <div className={styles.headerBrand}>
                    <h2>Marketing Assets</h2>
                    <h3>{brand}</h3>
                </div>
            </div>
            <br/> */}
            <TabNavigation />
            <iframe src={getMALink(brand,country)} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>
        </div>
    )
}