import React, { useContext , useState , useEffect } from "react";
import styles from './UserIcon.module.css';
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import { LoggedInContext } from "../providers/LoggedInContext";
import { NavLink } from "react-router-dom";

export default function UserIcon(){

    const {user,handleLogOut} = useContext(LoggedInContext);

    const [showOptions,setShowOptions] = useState();

    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setShowOptions(newViewportWidth < 1000);
    }

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
        }
    },[]);

    return(
        <div className={styles.container}>
            <div className={styles.circularIcon} onClick={() => setShowOptions(true)}>
                {user.firstName[0]+user.lastName[0]}
            </div>
            <div className={styles.options} style={{display: `${showOptions? 'grid' : 'none'}`}} >
                <div className={styles.cross} onClick={() => setShowOptions(false)}><Cross /></div>
                {/* <button>Settings</button> */}
                <button className={styles.logout} onClick={handleLogOut}>Log Out</button>
                <div className={styles.nameIconCon}>
                    <div className={styles.circularIconInside}>
                        {user.firstName[0]+user.lastName[0]}
                    </div>
                    <p className={styles.firstName}>{user.firstName}</p>
                    <p className={styles.lastName}>{user.lastName}</p>
                </div>
            </div>
        </div>

    )
}