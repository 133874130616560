import React, { useContext, useEffect ,useState } from "react";
import styles from './UserCard.module.css';
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function UserCardHeader(){

    const {user} = useContext(LoggedInContext);

    if (user.viewUsers === 1){
        if(user.editUsers === 1){
            return (
                <div className={styles.containerHeader}>
                    <div>Name, Email & Defaults</div>
                   
                    <div className={styles.permissions}>View Users</div>
                    <div className={styles.permissions}>Edit Users</div>
                    <div className={styles.permissions}>View All Brands</div>
                    <div className={styles.permissions}>View All Countries</div>
                    <div>Options</div>
                    <div></div>
                    <div></div>
                </div>
            )
        } else {
            return (
                <div className={styles.conCon}>
                    <div className={`${styles.containerHeaderHeader} ${styles.viewOnly}`}>
                        <div></div>
                        <div className={styles.permissionsHeader}><strong>User Permissions</strong></div>
                    </div>
                    <div className={`${styles.containerHeader} ${styles.viewOnly}`}>
                        <div>Name, Email & Default Country</div>
                        <div className={styles.permissions}>View All Brands?</div>
                        <div className={styles.permissions}>View All Countries?</div>
                    </div>
                </div>
            )
        }
    }
}