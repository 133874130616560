import React, { useContext, useEffect} from "react";
import styles from './FolderPage.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";

export default function FolderPage(){
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const linkObject = {
        'Premio': "https://app.box.com/embed/s/4jnl2fv4y7j0yj52yrmyfmlnoxi7thsd?sortColumn=date",
        'Vulco': "https://app.box.com/embed/s/fwv94rky6k7eyqi1nq5b49e22qqjxpic?sortColumn=date",
        'Super Service': "https://app.box.com/embed/s/7js5bo64yhet7f9rcr740vis2h9kf70j?sortColumn=date",
    }
    const handleReturnClick = () => {
        navigate('/home');
    }

    useEffect(() => {
        setDisable(false);
    },[]);

    return(
        <main>
            <div className={styles.container}>
                <div className={styles.navPageHeader}>
                    <button onClick={handleReturnClick}>Return</button>
                    <div className={styles.headerBrand}>
                        <h2>General Assets</h2>
                        <h3>{brand}</h3>
                    </div>
                </div>
                <br/>
                <iframe src={linkObject[brand]} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>
            </div>
        </main>
    )
}