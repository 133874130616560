import React, { useContext , useState , useEffect } from "react";
import styles from './BrandButton.module.css';
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import { ReactComponent as PremioColor } from '../imgs/logos/premioColor_GER Premio Logo.svg';
import { ReactComponent as PremioBlack } from '../imgs/logos/premioBlack_GER Premio Logo Black.svg';
import { ReactComponent as VulcoColor } from '../imgs/logos/vulcoColor_FRA Vulco Logo.svg';
import { ReactComponent as VulcoBlack } from '../imgs/logos/vulcoBlack_FRA Vulco Logo Black.svg';

export default function BrandButton({brandName,handleSelectBrand}){
    const {brand,setBrand,country,setCountry} = useContext(BrandCountryContext)

    const brandArray = [
        {
            brandName: 'Premio',
            svgSelected: <PremioColor />,
            svgNotSelected: <PremioColor />
        },
        {
            brandName: 'Vulco',
            svgSelected: <VulcoColor />,
            svgNotSelected: <VulcoColor />
        }
    ];

    const [brandSVG,setBrandSVG] = useState('');

    useEffect(() => {   
        const svgArray = [];
        brandArray.map((brandObj) => {
            if(brandObj.brandName === brandName){
                if(brand === brandName){
                    svgArray.push(brandObj.svgSelected);
                } else {
                    svgArray.push(brandObj.svgNotSelected);
                }
                
            }
        });
        setBrandSVG(svgArray[0]);
    },[brand]);


    return (
        <div className={`${styles.brandButton} ${brandName === brand? styles.selected : ''}`} style={{opacity: `${brandName === brand? 1 : 0.5}` }}onClick={() => handleSelectBrand(brandName)}>
            <div>
                {brandSVG}
            </div>
            <div>

            </div>
        </div>
    )
}