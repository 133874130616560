import React from "react";
import styles from './Footer.module.css';
import { NavLink } from "react-router-dom";

export default function FooterExternal(){

    const handleNavClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <footer className={styles.external}>
            <div className={styles.links}>
                <NavLink to="/login" onClick={handleNavClick}>Login</NavLink>
                <NavLink to="/register" onClick={handleNavClick}>Register</NavLink>
                <NavLink to="/cookiePolicy" onClick={handleNavClick}>Cookie Policy</NavLink>
                <NavLink to="/privacyPolicy" onClick={handleNavClick}>Privacy Policy</NavLink>
            </div>
            <div className={styles.footerFooter}>
            <p>© Stratela Ltd 2024</p>
            </div>
        </footer>
    )
}