import React, { useContext, useEffect ,useState } from "react";
import styles from './UserCard.module.css';
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function UserCardAwait({userObj,handleGetUsers}){
    const {user,backendEndpoint} = useContext(LoggedInContext);

    const handleDelete = () => {
        if(window.confirm(`Are you sure you want to permanently delete ${userObj.firstName} ${userObj.lastName}'s account?`)){
            fetch(backendEndpoint+'/users',{
                method: 'DELETE',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({id: userObj.id}),
                credentials: "include"
            })
            .then((res) => res.text())
            .then((data) => {
                handleGetUsers();
            })
            .catch((err) => console.log(err));
        }
    }
    const authoriseUser = () => {
        fetch(backendEndpoint+'/userAuth',{
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: userObj.id, stratAuth: 1}),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            handleGetUsers();
        })
        .catch((err) => console.log(err));
    }

    if (user.viewUsers === 1){
        if(user.editUsers === 1){
            return (
                <div className={styles.container}>
                     <div className={styles.nameEmail}>
                        <div>{userObj.firstName+" "+userObj.lastName}</div>
                        <div className={styles.small}>{userObj.email}</div>
                        <div className={styles.small}>{userObj.defaultBrand} - {userObj.defaultCountry}</div>
                    </div>
                    <div className={styles.checkContainer}>{userObj.viewUsers === 1? <i className="fi fi-sr-check-circle"></i> : ''}</div>
                    <div className={styles.checkContainer}>{userObj.editUsers === 1? <i className="fi fi-sr-check-circle"></i> : ''}</div>
                    <div className={styles.checkContainer}>{userObj.viewAllBrands === 1? <i className="fi fi-sr-check-circle"></i> : ''}</div>
                    <div className={styles.checkContainer}>{userObj.viewAllCountry === 1? <i className="fi fi-sr-check-circle"></i> : ''}</div>
                    <div className={styles.inviteLink}><p></p><button onClick={authoriseUser}>Authorise User</button></div>
                    <button>Edit</button>
                    <button className={styles.delete} onClick={handleDelete}>Delete</button>
                </div>
            )
        } else {
            return (
                <div className={`${styles.container} ${styles.viewOnly}`}>
                    <div className={styles.nameEmail}>
                        <div>{userObj.firstName+" "+userObj.lastName}</div>
                        <div className={styles.small}>{userObj.email}</div>
                        <div className={styles.small}>{userObj.defaultBrand} - {userObj.defaultCountry}</div>
                    </div>
                    <div className={styles.checkContainer}>{userObj.viewAllBrands === 1? <i className="fi fi-sr-check-circle"></i> : ''}</div>
                    <div className={styles.checkContainer}>{userObj.viewAllCountry === 1? <i className="fi fi-sr-check-circle"></i> : ''}</div>
                </div>
            )
        }
    }
    
}