import React from "react";
import styles from '../cookies/CookiesExternal.module.css';
import FooterExternal from "../../footer/FooterExternal";
import PrivacyPolicyContent from "../../privacyPolicy/PrivacyPolicyContent";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicyExternal(){

    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/');
    }

    return (
        <>
            <main className={styles.background}>
                <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneLower}>
                            <h3>Privacy Policy</h3>
                            <br/>
                            <PrivacyPolicyContent/>
                            <button onClick={navigateToLogin} className={styles.returnBtn}>Go to Login</button>
                        </div>
                    </div>
                </div>
            </main>
            <FooterExternal />
        </>
    )
}