import React from "react";
import styles from './CookiesExternal.module.css';
import FooterExternal from "../../footer/FooterExternal";
import CookiePolicyContent from "../../cookieBanner/CookiePolicyContent";
import { useNavigate } from "react-router-dom";

export default function CookiesExternal(){

    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/');
    }

    return (
        <>
            <main className={styles.background}>
                <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneLower}>
                            <h3>Cookie Policy</h3>
                            <br/>
                            <CookiePolicyContent />
                            <button onClick={navigateToLogin} className={styles.returnBtn}>Go to Login</button>
                        </div>
                    </div>
                </div>
            </main>
            <FooterExternal />
        </>
    )
}