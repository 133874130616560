import React, { useContext, useEffect, useState } from "react";
import styles from './Header.module.css';
import { ReactComponent as Burger } from '../../components/imgs/icons/two lines-27.svg'
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import { NavLink, useNavigate } from "react-router-dom";
import { LoggedInContext } from "../providers/LoggedInContext";
import UserIcon from "../userIcon/UserIcon";

export default function Header(){
    const navigate = useNavigate();
    const {loggedIn,handleLogOut,handleCheckLogin,user} = useContext(LoggedInContext);

    const [mobileVersion,setMobileVersion] = useState(true);
    const [navBarShow,setNavBarShow] = useState(false);
    const [headerColor,setHeaderColor] = useState(false);
    const [lightTheme,setLightTheme] = useState();
    const [headerPos,setHeaderPos] = useState(0);


    const toggleNavBar = () => {
        setNavBarShow((prev) => !prev);
    }
    
    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setMobileVersion(newViewportWidth < 1000);
        setNavBarShow(newViewportWidth > 1000);
    }

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
        }
    },[]);

    
    const handleLogoClick = () => {
        navigate('/home');
    }

    const handleNavClick = () => {
        window.scrollTo(0,0);
        if(mobileVersion){
            setNavBarShow(false);
        }
    }
    const navigateAndClose = (pagePath,sectionId) => {
        if(mobileVersion){
            setNavBarShow(false);
        }
    }

    useEffect(() => {
        navigate('/');
    },[loggedIn]);

    if (user.viewUsers === 1){
        return (
            <header style={{top: headerPos}} className={`${styles.headerTextWhite}`}>
            <div className={styles.imgContainer} onClick={handleLogoClick}>
                Goodyear Retail Brand Hub
            </div>
            <div className={`${styles.burgerMenuButton} ${mobileVersion? styles.displayGrid: styles.hide}`} onClick={toggleNavBar}>
                <Burger />
            </div>
            <nav className={`${navBarShow? styles.displayBlock : styles.hide}`}>
                    <ul>
                        <li className={styles.closeButton}>
                            <a onClick={() => setNavBarShow(false)}><Cross /></a>
                        </li>
                        <li>
                            <NavLink to="home" onClick={handleNavClick}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="users" onClick={handleNavClick}>Users</NavLink>
                        </li>
                        <li>
                            <UserIcon />
                        </li>
                    </ul>
            </nav>
            </header>
        )
    } else {
        return (
            <header style={{top: headerPos}} className={`${styles.headerTextWhite}`}>
            <div className={styles.imgContainer} onClick={handleLogoClick}>
                Goodyear Assets
            </div>
            <div className={`${styles.burgerMenuButton} ${mobileVersion? styles.displayGrid: styles.hide}`} onClick={toggleNavBar}>
                <Burger />
            </div>
            <nav className={`${navBarShow? styles.displayBlock : styles.hide}`}>
                    <ul>
                        <li className={styles.closeButton}>
                            <a onClick={() => setNavBarShow(false)}><Cross /></a>
                        </li>
                        <li>
                            <NavLink to="home" onClick={handleNavClick}>Home</NavLink>
                        </li>
                        <li>
                            <UserIcon />
                        </li>
                    </ul>
            </nav>
            </header>
        )
    }
}