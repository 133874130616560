import React from "react";

export default function CookiePolicyContent(){
    return(
        <>
            <p>Last Updated: 12/11/2024</p>
            <br/>
            <h4>What are Cookies?</h4>
            <br/>
            <p>Cookies are small text files stored on your device when you visit a website. They help us provide you with a seamless and secure browsing experience.</p>
            <br/>

            <h4>Essential Cookies</h4>
            <br/>

            <p>Our website uses only essential cookies, which are necessary for the website to function correctly. These cookies enable core functionality such as security, network management, and accessibility. They do not track personal data, nor do they collect information for marketing purposes.</p>
            <br/>

            <h4>Why We Use Essential Cookies</h4>
            <br/>

            <p>We use essential cookies to:</p>
            <br/>
            <ul>
                <li>Enable core website functions.</li>
                <li>Maintain security and protect against fraud.</li>
                <li>Improve website performance.</li>
            </ul>
            <br/>
            <p>Since these cookies are essential for the proper functioning of our site, they cannot be disabled. However, you can adjust your browser settings to block or alert you about these cookies. Please note, doing so may affect how our website functions.</p>
        </>
    )
}