import React, { useContext } from "react";
import styles from './BrandCountrySelect.module.css';
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import FlagIcons from "../flagIcons/FlagIcons";


export default function CountryButton({countryObj,handleSelectCountry}){
    const {brand,setBrand,country,setCountry} = useContext(BrandCountryContext);

    return (
        <div className={`${styles.countryButton} ${countryObj.country === country? styles.selected : ''}`} onClick={() => handleSelectCountry(countryObj)}>
            <div className={styles.flagContainer}><FlagIcons country={countryObj.country} height="1.5rem" selected={countryObj.country === country}/></div>
            <p>{countryObj.country}</p>
        </div>
    )
}