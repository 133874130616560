import React from "react";
import styles from './Home.module.css';

export default function HomeButton({pagePath,name,tagLine,handleNavClick,imgPath}){
    return (
        <div className={styles.buttonMain} onClick={() => handleNavClick(pagePath)}>
            <div className={styles.imgContainer} style={{backgroundImage: `url(${imgPath})`}}>
                <div className={styles.imgOverlay}></div>
            </div>
            <div className={styles.textContainer}>
                <h2>{name}</h2>
                <p>{tagLine}</p>
            </div>
        </div>
    )
}