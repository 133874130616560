import React from "react";

export default function PrivacyPolicyContent(){
    return(
        <>
            <p>Last Updated: 12/11/2024</p>
            <br/>
            <h4>1. Introduction</h4>
            <br/>
            <p>Goodyear Retail Brand Hub respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your information when you use our website to create an account.</p>
            <br/>
            <h4>2. Information We Collect</h4>
            <br/>
            <p>To create an account on Goodyear Retail Brand Hub, we collect the following personal information:</p>
            <br/>
            <p>First Name</p>
            <p>Last Name</p>
            <p>Email Address</p>
            <br/>
            <p>This information is required to set up your account and provide you with access to our services.</p>
            <br/>
            <h4>3. How We Use Your Information</h4>
            <br/>
            <p>We use your personal information for the following purposes:</p>
            <br/>
            <p><strong>Account Creation and Management:</strong> To create and manage your account on our website.</p>
            <p><strong>Communication:</strong> To send important updates, respond to your inquiries, and provide information related to your account.</p>
            <br/>
            <h4>4. Data Security</h4>
            <br/>
            <p>We implement reasonable security measures to protect your personal information from unauthorized access, use, disclosure, or alteration. However, no method of data transmission or storage is entirely secure, so we cannot guarantee absolute security.</p>
            <br/>
            <h4>5. Data Sharing</h4>
            <br/>
            <p>We do not share, sell, rent, or disclose your personal information to third parties. Your information is used solely for account-related purposes as outlined in this Privacy Policy.</p>
            <br/>
            <h4>6. Your Rights</h4>
            <br/>
            <p>You have the following rights regarding your personal information:</p>
            <br/>
            <p><strong>Access:</strong> You may request access to the personal information we hold about you.</p>
            <p><strong>Correction:</strong> You may request that we correct any inaccurate or incomplete personal information.</p>
            <p><strong>Deletion:</strong> You may request that we delete your account and personal information, subject to certain legal obligations.</p>
            <br/>
            <p>To exercise these rights, please contact us at it@stratela.co.uk.</p>
            <br/>
            <h4>7. Changes to this Privacy Policy</h4>
            <br/>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this Privacy Policy periodically.</p>
            <br/>
            <h4>8. Contact Us</h4>
            <br/>
            <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
            <br/>
            <p>it@stratela.co.uk</p>
        </>
    )
}