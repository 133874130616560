import './App.css';
import { Route , Routes , BrowserRouter, Navigate } from 'react-router-dom';
import Root from './components/root/Root';
import Home from '../src/components/pages/home/Home';
import GeneralAssets from './components/pages/generalAssets/GeneralAssets';
import { BrandCountryProvider } from './components/providers/BrandCountryProvider';
import Guidelines from './components/pages/guidelines/Guidelines';
import Login from './components/pages/login/Login';
import { LoggedInProvider } from './components/providers/LoggedInContext';
import PrivateRoutes from './components/pages/login/PrivateRoutes';
import Users from './components/pages/users/Users';
import NewUserLogin from './components/pages/newUserLogin/NewUserLogin';
import FolderPage from './components/pages/folderPage/FolderPage';
import LogosSignage from './components/pages/logosSignage/LogosSignage';
import MarketingAssets from './components/pages/marketingAssets/MarketingAssets';
import Regsiter from './components/pages/register/Register';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import CookieBanner from './components/cookieBanner/CookieBanner';
import CookiesExternal from './components/pages/cookies/CookiesExternal';
import PrivacyPolicyExternal from './components/pages/privacy/PrivacyPolicyExternal';
import PrivacyPolicyInternal from './components/pages/privacy/PrivacyPolicyInternal';
import CookiesInternal from './components/pages/cookies/CookiesInternal';

function App() {
  return (
    <>
      <LoggedInProvider>
        <BrandCountryProvider>
          <LoadingScreen />
          <CookieBanner />
          <BrowserRouter>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={ <Root/>}>
                  <Route index element={<Navigate to="home"/>}/>
                  <Route path="home" element={ <Home/> }/>
                  <Route path="generalAssets" element={ <GeneralAssets/> }/>
                  <Route path="guidelines" element={ <Guidelines/> }/>
                  <Route path="logosSignage" element={ <LogosSignage/> }/>
                  <Route path="marketingAssets" element={ <MarketingAssets/> }/>
                  <Route path="users" element={ <Users/> }/>
                  <Route path="folderPage" element={ <FolderPage/> }/>
                  <Route path="/cookiesInt" element={ <CookiesInternal /> }/>
                  <Route path="/privacyInt" element={ <PrivacyPolicyInternal/> }/>
                </Route>
              </Route>
              <Route path="/login" element={ <Login/> }/>
              <Route path="/register" element={ <Regsiter/> }/>
              <Route path="/invite/:inviteCode" element={ <NewUserLogin/> }/>
              <Route path="/cookiePolicy" element={ <CookiesExternal /> }/>
              <Route path="/privacyPolicy" element={ <PrivacyPolicyExternal/> }/>
            </Routes>
          </BrowserRouter>
        </BrandCountryProvider>
      </LoggedInProvider>
    </>
  );
}

export default App;
