import React, { useContext, useEffect ,useState } from "react";
import styles from './Users.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import UserCard from "./UserCard";
import UserCardAwait from "./UserCardAwait";
import UserCardHeader from "./UserCardHeader";
import { LoggedInContext } from "../../providers/LoggedInContext";

export default function Users(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);
    const {user,backendEndpoint,setLoading} = useContext(LoggedInContext);

    const [usersApproved,setUsersApproved] = useState([]);
    const [usersUnApproved,setUsersUnApproved] = useState([]);
    const [newFormOpen,setNewFormOpen] = useState(false);

    const [newFormData,setNewFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        pass: 'notARealPassword',
        viewUsers: false,
        editUsers: false,
        viewAllCountry: false,
        viewAllBrands: false,
        defaultCountry: '',
        defaultBrand: '',
        defaultLanguage: '',
        inviteCode: window.crypto.randomUUID()
    });

    const [emailAlreadyUsed,setEmailAlreadyUsed] = useState(false);

    useEffect(() => {
        setDisable(false);
        handleGetUsers();
        handlePageChange(window.location.pathname);
    },[]);

    const handleGetUsers = () => {
        handleGetUsersApproved();
    }
    const handleGetUsersApproved = async () => {
        setLoading(true);
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(backendEndpoint+'/users',{
            method: 'GET',
            headers: headers,
            credentials: 'include',
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            sortUsersAuth(recObj);
            setLoading(false);
        })
        .catch((err) => console.log(err));

    }
    const sortUsersAuth = (objArray) => {
        let approvedArray = [];
        let unApprovedArray = [];
        objArray.map((obj) => {
            if(obj.stratAuth === 1){
                approvedArray.push(obj);
            } else {
                unApprovedArray.push(obj);
            }
        });
        setUsersApproved(approvedArray);
        setUsersUnApproved(unApprovedArray);
    }

    const handleAddUser = async () => {
        setLoading(true);
        fetch(backendEndpoint+'/users',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newFormData),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            handleGetUsers();
        })
        .catch((err) => console.log(err));
    }

    const checkEmailAlreadyInUse = async (email) => {
        fetch(backendEndpoint+'/emailCheck',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email}),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            if(recObj.status){
                setEmailAlreadyUsed(true);
            } else {
                setEmailAlreadyUsed(false);
            }
        })
        .catch((err) => console.log(err));
    }

    const handleChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.value}));
    }
    const handleCheckboxChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.checked}));
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        //check form filled in correctly
        //check email hasn't been used
        if(newFormData.firstName === '' || newFormData.lastName === '' || newFormData.email === '' || newFormData.defaultCountry === '' || newFormData.defaultBrand === '' || newFormData.defaultLanguage === '' ){
            alert(`All 'Personal Details' and 'Default Options' fields MUST be filled before submitting`);
        } else if (emailAlreadyUsed){
            alert('Email already in use');
        } else {
            handleAddUser();
            setNewFormOpen(false);
            setNewFormData({
                firstName: '',
                lastName: '',
                email: '',
                pass: 'notARealPassword',
                viewUsers: false,
                editUsers: false,
                viewAllCountry: false,
                viewAllBrands: false,
                defaultCountry: '',
                defaultBrand: '',
                defaultLanguage: '',
                inviteCode: window.crypto.randomUUID()
            });
        }

    }
    const handleCancel = () => {
        setNewFormOpen(false);
        setNewFormData({
            firstName: '',
            lastName: '',
            email: '',
            pass: 'notARealPassword',
            viewUsers: false,
            editUsers: false,
            viewAllCountry: false,
            viewAllBrands: false,
            defaultCountry: '',
            defaultBrand: '',
            defaultLanguage: '',
            inviteCode: window.crypto.randomUUID()
        });
    }
    if (user.viewUsers === 1){
        if(user.editUsers === 1){
            return (
                <>
                    <div className={`${styles.newUser} ${newFormOpen? styles.newFormOpen : ''}`}>
                        <form onSubmit={handleSubmit} className={`${styles.changerPane} ${newFormOpen? styles.newFormOpen : ''}`}>
                            <h2>New User</h2>
                            <div className={styles.formInner}>
                                <div className={styles.personalCon}>
                                    <h3>Personal Details</h3>
                                    <div className={styles.nameInputs}>
                                        <label><p>First Name</p><input type="text" onChange={handleChange} name="firstName" value={newFormData.firstName}/></label>
                                        <label><p>Last Name</p><input type="text" onChange={handleChange} name="lastName" value={newFormData.lastName}/></label>
                                        <label><p>Email</p><input type="text" onChange={(e) => {handleChange(e);checkEmailAlreadyInUse(e.target.value);}} name="email" value={newFormData.email}/></label>
                                        <p className={styles.emailMsg}>{emailAlreadyUsed? 'Email already in use' : ''}</p>
                                    </div>
                                </div>
                                <div className={styles.rolesCon}>
                                    <h3>Roles</h3>
                                    <div className={styles.userRoles}>
                                        <label><p>View Users</p><input type="checkbox" name="viewUsers" onChange={handleCheckboxChange} checked={newFormData.viewUsers}/></label>
                                        <label><p>Edit Users</p><input type="checkbox" name="editUsers" onChange={handleCheckboxChange} checked={newFormData.editUsers}/></label>
                                        <label><p>View All Brands</p><input type="checkbox" name="viewAllBrands" onChange={handleCheckboxChange} checked={newFormData.viewAllBrands}/></label>
                                        <label><p>View All Countries</p><input type="checkbox" name="viewAllCountry" onChange={handleCheckboxChange} checked={newFormData.viewAllCountry}/></label>
                                    </div>
                                </div>
                                <div className={styles.defaultCon}>
                                    <h3>Default Options</h3>
                                    <div className={styles.defaults}>
                                        <label><p>Default Brand</p><input type="text" onChange={handleChange} name="defaultBrand" value={newFormData.defaultBrand}/></label>
                                        <label><p>Default Country</p><input type="text" onChange={handleChange} name="defaultCountry" value={newFormData.defaultCountry}/></label>
                                        <label><p>Default Language</p><input type="text" onChange={handleChange} name="defaultLanguage" value={newFormData.defaultLanguage}/></label>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.footer}>
                            <input onClick={handleCancel} className={styles.cancel} type="button" value="Cancel" /><input type="submit" value="Add User" />
                            </div>
                        </form>
                    </div>
                    <div className={styles.container}>
                        <button className={styles.addButton} onClick={() => setNewFormOpen(true)}>Add User</button>
                        <h2>Authorised</h2>
                        <br/>
                        <UserCardHeader/>
                        {usersApproved.map((userObj) => <UserCard key={userObj.id} userObj={userObj} handleGetUsers={handleGetUsers}/>)}
                        <br/>
                        <h2>Pending Authorisation</h2>
                        <br/>
                        <UserCardHeader/>
                        {usersUnApproved? usersUnApproved.map((userObj) => <UserCardAwait key={userObj.id} userObj={userObj} handleGetUsers={handleGetUsers}/>) : <h3>No users pending authorisation</h3>}
                    </div>
                </>
            )
        } else {
            return (
                <div className={styles.container}>
                    <UserCardHeader/>
                    {usersApproved.map((userObj) => <UserCard userObj={userObj} handleGetUsers={handleGetUsers}/>)}
                </div>
            )
        }
    } else {
        return (
            <div className={styles.container} style={{height: '100vh'}}>
                <h2>You do not have access to view this page</h2>
            </div>
        )
    }
}