import React, { useContext, useEffect} from "react";
import styles from './GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";

export default function GeneralAssets(){
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const linkObject = {
        'Premio': "https://app.box.com/embed/s/3g4pkikshv4vn621pr1rpvxg1r8sav4e?sortColumn=date",
        'Vulco': "https://app.box.com/embed/s/kzikqrp4yuql1l0gqr7480naruj083pf?sortColumn=date",
        'Super Service': "https://app.box.com/embed/s/7js5bo64yhet7f9rcr740vis2h9kf70j?sortColumn=date",
    }
    const handleReturnClick = () => {
        navigate('/home');
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    return(
        <div className={styles.container}>
            {/* <div className={styles.navPageHeader}>
                <button onClick={handleReturnClick}>Return to Home</button>
                <div className={styles.headerBrand}>
                    <h2>General Assets</h2>
                    <h3>{brand}</h3>
                </div>
            </div> */}
            <TabNavigation selected={brand}/>
            <iframe src={linkObject[brand]} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>
        </div>
    )
}