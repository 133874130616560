import React, { useContext, useEffect, useState } from "react";
import styles from './BrandCountrySelect.module.css';
import CountryButton from "./CountryButton";
import BrandButton from "./BrandButton";
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import LanguageButton from "./LanguageButton";
import { ReactComponent as PremioColor } from '../imgs/logos/premioColor_GER Premio Logo.svg';
import { ReactComponent as VulcoColor } from '../imgs/logos/vulcoColor_FRA Vulco Logo.svg';
import { LoggedInContext } from "../providers/LoggedInContext";

export default function BrandCountrySelect(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,currentPage,changerActive,setChangerActive} = useContext(BrandCountryContext);
    const {user} = useContext(LoggedInContext);

    const [currentLanguageArray,setCurrentLanguageArray] = useState([
        {
            languageName: 'English',
            country: 'GB'
        }
    ]);

    const toggleActive = () => {
        setChangerActive((prev) => !prev);
    }

    useEffect(() => {
        if(changerActive){
            window.scrollTo(0,0);
        }
    })

    const brandLogoObject = {
        "Premio": <PremioColor />,
        "Vulco": <VulcoColor />
    }


    const brandArray = ['Premio','Vulco'];
    const countryArray = [
        {
            country: 'Belgium',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Dutch',
                    country: 'The Netherlands'
                },
                {
                    languageName: 'French',
                    country: 'France'
                },
                {
                    languageName: 'German',
                    country: 'Germany'
                }               
            ]
        },
        {
            country: 'Bosnia',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Bosnian',
                    country: 'Bosnia'
                },
                {
                    languageName: 'Croatian',
                    country: 'Croatia'
                },
                {
                    languageName: 'Serbian',
                    country: 'Serbia'
                }
            ]
        },
        {
            country: 'Bulgaria',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Bulgarian',
                    country: 'Bulgaria'
                }
            ]
        },
        {
            country: 'Croatia',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Croatian',
                    country: 'Croatia'
                }
            ]
        },
        {
            country: 'Czechia',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Czech',
                    country: 'Czechia'
                }
            ]
        },
        {
            country: 'France',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'French',
                    country: 'France'
                }
            ]
        },
        {
            country: 'Germany',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'German',
                    country: 'Germany'
                }
            ]
        },
        {
            country: 'Greece',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Greek',
                    country: 'Greece'
                }
            ]
        },
        {
            country: 'Hungary',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Hungarian',
                    country: 'Hungary'
                }
            ]
        },
        // {
        //     country: 'Italy',
        //     brands: ['Premio'],
        //     languages: [
        //         {
        //             languageName: 'Italian',
        //             country: 'Italy'
        //         }
        //     ]
        // },
        {
            country: 'North Macedonia',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Macedonian',
                    country: 'North Macedonia'
                },
                {
                    languageName: 'Albanian',
                    country: 'Albania'
                }
            ]
        },
        {
            country: 'The Netherlands',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Dutch',
                    country: 'The Netherlands'
                }
            ]
        },
        {
            country: 'Poland',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Polish',
                    country: 'Poland'
                }
            ]
        },
        {
            country: 'Portugal',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Portuguese',
                    country: 'Portugal'
                }
            ]
        },
        {
            country: 'Romania',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Romanian',
                    country: 'Romania'
                }
            ]
        },
        {
            country: 'Serbia',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Serbian',
                    country: 'Serbia'
                }
            ]
        },
        {
            country: 'Slovakia',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'Slovak',
                    country: 'Slovakia'
                }
            ]
        },
        {
            country: 'Slovenia',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Slovenian',
                    country: 'Slovenia'
                }
            ]
        },
        {
            country: 'Spain',
            brands: ['Vulco'],
            languages: [
                {
                    languageName: 'Spanish',
                    country: 'Spain'
                }
            ]
        },
        {
            country: 'Switzerland',
            brands: ['Premio'],
            languages: [
                {
                    languageName: 'French',
                    country: 'France'
                },
                {
                    languageName: 'German',
                    country: 'Germany'
                },
                {
                    languageName: 'Italian',
                    country: 'Italy'
                }
            ]
        }
    ];
    const languageArray = [
        {
           languageName: 'English',
           country: 'GB'
        },
        {
            languageName: 'French',
            country: 'France'
        },
        {
            languageName: 'Spanish',
            country: 'Spain'
        },
        {
            languageName: 'German',
            country: 'Germany'
        },
        {
            languageName: 'Portuguese',
            country: 'Portugal'
        },
        {
            languageName: 'Polish',
            country: 'Poland'
        },
        {
            languageName: 'Czech',
            country: 'Czechia'
        },
        {
            languageName: 'Italian',
            country: 'Italy'
        },
        {
            languageName: 'Dutch',
            country: 'The Netherlands'
        },
        {
            languageName: 'Greek',
            country: 'Greece'
        }
    ];

    const handleSelectBrand = (name) => {
        setBrand(name);
        setCountry('');
        setLanguage('');
    }

    const handleSelectCountry = (countryObj) => {
        setCountry(countryObj.country);
        setLanguage(countryObj.languages[0].languageName);
        setCurrentLanguageArray(countryObj.languages)
    }

    const handleSelectLanguage = (name) => {
        setLanguage(name);
    }

    const handleDone = () => {
        if(brand !== '' && country !== ''){
            toggleActive();
        } else {
            alert('A Brand and Country must be selected before closing');
        }
    }

    if(user.viewAllBrands === 1){
        return (
            <div className={`${styles.container} ${changerActive? styles.changerActive : ''} ${disable? styles.disable : ''}`}>
                <div className={`${styles.changerContainer} ${changerActive? styles.changerActive : ''}`} >
                    <div className={`${styles.changerPane} ${changerActive? styles.changerActive : ''}`}>
                        <h2>Select Brand</h2>
                        <div className={styles.brandSelectCon}>
                            {brandArray.map((brandName) => <BrandButton brandName={brandName} handleSelectBrand={handleSelectBrand} />)}
                        </div>
                        <h2>Select Country</h2>
                        <div className={styles.countrySelectCon}>
                            {countryArray.map((countryObj) => countryObj.brands.includes(brand)? <CountryButton countryObj={countryObj} handleSelectCountry={handleSelectCountry} /> : '')}
                        </div>
                        {/* <h2 style={{display: `${currentLanguageArray.length < 2? 'none' : 'block'}`}}>Select Asset Language</h2>
                        <div style={{display: `${currentLanguageArray.length < 2? 'none' : 'flex'}`}} className={styles.countrySelectCon}>
                            {currentLanguageArray.map((languageObj) => <LanguageButton languageObj={languageObj} handleSelectLanguage={handleSelectLanguage} />)}
                        </div> */}
                        <div className={styles.footer}>
                            <button onClick={handleDone}>Done</button>
                        </div>
                    </div>
                </div>
                <div className={`${styles.bannerContainer} ${changerActive? styles.changerActive : ''}`}>
                    <div className={styles.pageHeading}>
                        <h1>{currentPage}</h1>
                    </div>
                    <div className={styles.selectedContent} onClick={toggleActive}>
                        <div className={styles.hoverText}>
                            <p>Click here to change Brand or Country</p>
                        </div>
                        <div className={styles.brandCountry}>
                            <div className={styles.logoContainer}>
                                {brandLogoObject[brand]}
                            </div>
                            <div className={styles.brandCountryTextCon}>
                                <div className={styles.countryText}>
                                    {country}
                                </div>
                                {/* <div className={styles.langText} style={{display: `${language === ''? 'none' : 'block'}`}}>
                                    {language}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        )
    } else {
        if(user.viewAllCountry === 1){
            return (
                <div className={`${styles.container} ${changerActive? styles.changerActive : ''} ${disable? styles.disable : ''}`}>
                    <div className={`${styles.changerContainer} ${changerActive? styles.changerActive : ''}`} >
                        <div className={`${styles.changerPane} ${changerActive? styles.changerActive : ''}`}>
                            <h2>Select Country</h2>
                            <div className={styles.countrySelectCon}>
                                {countryArray.map((countryObj) => countryObj.brands.includes(brand)? <CountryButton countryObj={countryObj} handleSelectCountry={handleSelectCountry} /> : '')}
                            </div>
                            {/* <h2 style={{display: `${currentLanguageArray.length < 2? 'none' : 'block'}`}}>Select Asset Language</h2>
                            <div style={{display: `${currentLanguageArray.length < 2? 'none' : 'flex'}`}} className={styles.countrySelectCon}>
                                {currentLanguageArray.map((languageObj) => <LanguageButton languageObj={languageObj} handleSelectLanguage={handleSelectLanguage} />)}
                            </div> */}
                            <div className={styles.footer}>
                                <button onClick={handleDone}>Done</button>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.bannerContainer} ${changerActive? styles.changerActive : ''}`}>
                        <div className={styles.pageHeading}>
                            <h1>{currentPage}</h1>
                        </div>
                        <div className={styles.selectedContent} onClick={toggleActive}>
                            <div className={styles.hoverText}>
                                Click here to change Country
                            </div>
                            <div className={styles.brandCountry}>
                                <div className={styles.logoContainer}>
                                    {brandLogoObject[brand]}
                                </div>
                                <div className={styles.brandCountryTextCon}>
                                    <div className={styles.countryText}>
                                        {country}
                                    </div>
                                    {/* <div className={styles.langText} style={{display: `${language === ''? 'none' : 'block'}`}}>
                                        {language}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            )
        } else {
            return (
                <div className={`${styles.container} ${changerActive? styles.changerActive : ''} ${disable? styles.disable : ''}`}>
                    {/* <div className={`${styles.changerContainer} ${changerActive? styles.changerActive : ''}`} >
                        <div className={`${styles.changerPane} ${changerActive? styles.changerActive : ''}`}>
                            <h2>Select Country</h2>
                            <div className={styles.countrySelectCon}>
                                {countryArray.map((countryObj) => countryObj.brands.includes(brand)? <CountryButton countryObj={countryObj} handleSelectCountry={handleSelectCountry} /> : '')}
                            </div>
                            <h2 style={{display: `${currentLanguageArray.length < 2? 'none' : 'block'}`}}>Select Asset Language</h2>
                            <div style={{display: `${currentLanguageArray.length < 2? 'none' : 'flex'}`}} className={styles.countrySelectCon}>
                                {currentLanguageArray.map((languageObj) => <LanguageButton languageObj={languageObj} handleSelectLanguage={handleSelectLanguage} />)}
                            </div>
                            <div className={styles.footer}>
                                <button onClick={handleDone}>Done</button>
                            </div>
                        </div>
                    </div> */}
                    <div className={`${styles.bannerContainer} ${styles.noHover}`}>
                        <div className={styles.pageHeading}>
                            <h1>{currentPage}</h1>
                        </div>
                        <div className={styles.selectedContent}>
                            <div className={styles.hoverText}>
                                
                            </div>
                            <div className={styles.brandCountry}>
                                <div className={styles.logoContainer}>
                                    {brandLogoObject[brand]}
                                </div>
                                <div className={styles.brandCountryTextCon}>
                                    <div className={styles.countryText}>
                                        {country}
                                    </div>
                                    {/* <div className={styles.langText} style={{display: `${language === ''? 'none' : 'block'}`}}>
                                        {language}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            )
        }
    }
    
}