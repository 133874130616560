import React from "react";
import styles from './Footer.module.css';
import { NavLink } from "react-router-dom";

export default function Footer(){

    const handleNavClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <footer>
            <div className={styles.links}>
                <NavLink to="/home" onClick={handleNavClick}>Home</NavLink>
                <NavLink to="/cookiesInt" onClick={handleNavClick}>Cookie Policy</NavLink>
                <NavLink to="/privacyInt" onClick={handleNavClick}>Privacy Policy</NavLink>
            </div>
            <div className={styles.footerFooter}>
            <p>© Stratela Ltd 2024</p>
            </div>
        </footer>
    )
}