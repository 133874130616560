import React, { useContext , useEffect } from "react";
import styles from './Home.module.css';
import BrandCountrySelect from "../../brandCountrySelect/BrandCountrySelect";
import { useNavigate } from "react-router-dom";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import HomeButton from "./HomeButtons";

export default function Home(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const handleNavClick = (path) => {
        navigate('/'+path)
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    return(
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <HomeButton handleNavClick={handleNavClick} pagePath={'guidelines'} name={'Guidelines'} tagLine={'Brand Specific'} imgPath={brand ==='Premio'? './imgs/home/Box__Premio_Guidelines.jpg' : './imgs/home/Box__Vulco_Guidelines.jpg'}/>
                <HomeButton handleNavClick={handleNavClick} pagePath={'logosSignage'} name={'Logos & Signage Assets'} tagLine={'Country & Language Specific'} imgPath={brand === 'Premio'? './imgs/home/Box__Premio_Logos.jpg' : './imgs/home/Box__Vulco_Logos.jpg'}/>
                <HomeButton handleNavClick={handleNavClick} pagePath={'marketingAssets'} name={'Marketing Assets'} tagLine={'Country & Language Specific'} imgPath={brand === 'Premio'? './imgs/home/Box__Premio_Marketing.jpg' : './imgs/home/Box__Vulco_Marketing.jpg'}/>
                <HomeButton handleNavClick={handleNavClick} pagePath={'generalAssets'} name={'General Assets'} tagLine={'Brand Specific'} imgPath={'./imgs/home/Box__General_Assets.jpg'}/>
            </div>
        </div>
    )
}