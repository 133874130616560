import React, { useContext , useEffect } from "react";
import styles from '../generalAssets/GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";

export default function LogosSignage(){
    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const linkObjectLogos = {
        'France': "https://app.box.com/embed/s/gjjta0xq5v19lf72p137n5crxh8foj02?sortColumn=date",
        'Spain':  "https://app.box.com/embed/s/e8kcvlrca4tfciqwboxjn8ynxtfxk2rc?sortColumn=date",
        'Portugal': "https://app.box.com/embed/s/6zrix7ihvyc9hdyevsa89uil328v2781?sortColumn=date",
        'Germany': "https://app.box.com/embed/s/3zgljvwnrajx939bsuan0ncivi9nwn5c?sortColumn=date",
        'Poland': "https://app.box.com/embed/s/qku2doqh3dpz4a7al1r4faqtf87nturw?sortColumn=date",
        'Greece': "https://app.box.com/embed/s/99qw625ze2vi9dg8t084w10llsca4st4?sortColumn=date",
        'The Netherlands': "https://app.box.com/embed/s/ljpemoy7h8szyboz5ehjfux8p5h5egxw?sortColumn=date",
        'Belgium': "https://app.box.com/embed/s/ew05jba8agaj58665i4m6t9c02hihkv4?sortColumn=date",
        'Switzerland': "https://app.box.com/embed/s/qkm5s7ih9vgsqc5ycow898ffefhbvg8j?sortColumn=date",
        'Czechia': "https://app.box.com/embed/s/7qinxj3ei8vmdscgh87fhobopad7gun0?sortColumn=date",
        'Bosnia': "https://app.box.com/embed/s/1a15l5hdjgyystbp7pbqf0nvpyh6jq6f?sortColumn=date",
        'Croatia': "https://app.box.com/embed/s/pzqmubotyar0g9sdgohxnuj39pgwtxvq?sortColumn=date",
        'North Macedonia': "https://app.box.com/embed/s/hbr57ww2lu3bwlkz18apyceo6aoi7xwv?sortColumn=date",
        'Slovenia': "https://app.box.com/embed/s/cihmhxcx9kiobgbxbg9xridczlx3cou7?sortColumn=date",
        'Bulgaria': "https://app.box.com/embed/s/rj2l5wyszqpz1bxl4wavdsjx12hlmxro?sortColumn=date",
        'Hungary': "https://app.box.com/embed/s/zkviyhouordsf866v5ns6zn9n754u2om?sortColumn=date",
        'Romania': "https://app.box.com/embed/s/175kt23r6bhmk2tpma07af9o7oz0t562?sortColumn=date",
        'Slovakia': "https://app.box.com/embed/s/tdmwwvpswndnyyvvsqgymb3j0r6drl2s?sortColumn=date",
        'Serbia': "https://app.box.com/embed/s/hatwss3svh63186jbnjcuws5vzowzuei?sortColumn=date"
    }
    
    const handleReturnClick = () => {
        navigate('/home');
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    return(
        <div className={styles.container}>
            <TabNavigation />
            <iframe src={linkObjectLogos[country]} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>
        </div>
    )
}